// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antifurto-jsx": () => import("./../../../src/pages/antifurto.jsx" /* webpackChunkName: "component---src-pages-antifurto-jsx" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-exposicao-legumes-fruta-jsx": () => import("./../../../src/pages/exposicao-legumes-fruta.jsx" /* webpackChunkName: "component---src-pages-exposicao-legumes-fruta-jsx" */),
  "component---src-pages-exposicao-padaria-pasteleria-jsx": () => import("./../../../src/pages/exposicao-padaria-pasteleria.jsx" /* webpackChunkName: "component---src-pages-exposicao-padaria-pasteleria-jsx" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-diversos-complementares-jsx": () => import("./../../../src/pages/produtos-diversos-complementares.jsx" /* webpackChunkName: "component---src-pages-produtos-diversos-complementares-jsx" */)
}

