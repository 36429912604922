/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.scss';
// You can delete this file if you're not using it
export const onInitialClientRender = () => {
  console.log('ReactDOM.render has executed');

    let script = document.createElement('script');

    script.src = '//js.hs-scripts.com/7409874.js';
    script.id = 'hs-script-loader';
    script.defer = true;
    script.async = true;
    setTimeout(() => {
        document.body.appendChild(script);
    }, 2000)

};
